<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col :md="isTogglingView && toggledViewData ? '8' : '12'">
        <b-card>
          <b-card-text>
            <DxGrid
              title="Timeline"
              :data-source="dataSource"
              :total-count="totalCount"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadTimelines()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-search="(v) => (keyword = v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-status-change="(v) => (status = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        :md="isTogglingView ? '4' : '12'"
        v-if="isTogglingView && toggledViewData"
      >
        <b-card>
          <b-card-text> </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import timelineHeadersApi from '@api/timeline_headers';
import { queryParameters } from '@/schema';

export default {
  name: 'HeaderBannersPage',
  components: {},
  data: () => ({
    isTogglingView: false,
    toggledViewData: null,
    dataSource: [],
    dataColumns: [
      { caption: 'Ticket No.', field: 'ticket_no' },
      { caption: 'Title', field: 'title' },
      { caption: 'Description', field: 'description' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
    status: 'O',
    keyword: null,
  }),
  mounted() {
    this.loadTimelines();
  },
  watch: {
    page(v) {
      this.loadTimelines();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadTimelines();
      }
    },
    status(v) {
      this.loadTimelines();
    },
    keyword(v) {
      this.loadTimelines();
    },
  },
  methods: {
    loadTimelines() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: this.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      if (this.keyword) {
        params.filter = this.keyword;
      }

      timelineHeadersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },

    onToggleView(event) {
      this.isTogglingView = event;

      if (!event) {
        this.toggledViewData = null;
      }
    },
    onViewDetail(item) {
      const { data } = item;
      if (data) {
        const { job_order } = data;

        if (job_order && job_order.data) {
          const { data } = job_order;
          if (data) {
            const url = `/pages/services-tools/job-order/view?id=${data.id}&activeTab=1`;
            window.open(url, '_blank');
          }
        }
      }
    },
    onUpdate(event) {
      const data = event.data;
      timelineHeadersApi
        .update(data)
        .then(({ data }) => {
          if (data) {
            this.toastConfig();
          }
        })
        .catch((err) => {
          if (err) {
            this.toastConfig('', 'error');
          }
        })
        .finally(() => {
          //
        });
    },
    onDelete(event) {
      timelineHeadersApi
        .delete(event)
        .then(() => {
          this.loadTimelines();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
